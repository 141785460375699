import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/graphic.png";
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
export default function Graphic() {
  const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Features</h2> */}
            <p className="mb-5">
              Website Content Writing Services
            </p>
            <h5 className='mt-3  fst-italic'>
              Nettroller enables you to transition to the cloud seamlessly,
              whether you’re transferring an off-the-shelf package, migrating
              legacy software, or developing a new application from scratch. Our
              strategic planning also provides valuable guidance for
              organizations with an existing cloud presence.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Optimized Web Content</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Article Writing</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Blog Writing</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Visual Content</h3>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
