import React from 'react';
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
// import service from "../images/7.png";
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
const ServicesInfo = (props) => {
    const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Features</h2> */}
            <p className="mb-3">{props.name}</p>
            <h5 className='  fst-italic'>
              An intuitive, mobile-first experience is a powerful way to
              differentiate your brand and grow a loyal customer base. Systems
              Limited offers a complete set of mobile app development services
              using native and cross-platform frameworks. We create outstanding
              mobile experiences on Android and iOS using scalable cloud backend
              solutions. We continuously innovate and release app updates on a
              regular cadence to ensure that your mobile apps remain
              indispensable for your customers and team members.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              {/* <img src={} class="img-fluid" alt="" /> */}
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Android Development using Java{props.name}</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>IOS Developmemt using Swift</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Android Developmemt using Kotlin</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React Native For Hybrid Application</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flutter For Hybrid Application</h3>
                  </div>
                </div>
              
              
              
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
    );
}
export default ServicesInfo
